import { useContext } from "react";
import { Helmet } from "react-helmet";

import { DevelopmentContext } from "../context/useDevelopmentMode";
import config from "../definitions/config.json";
import { theme } from "../definitions/theme";

import { AboutMe } from "./home/AboutMe";
import { Portfolio } from "./home/Portfolio";
import { Slogan } from "./home/Slogan";
import { Vita } from "./home/Vita";
import { Memberships } from "./Memberships";

export function Home() {
  const developmentMode = useContext(DevelopmentContext);
  return (
    <>
      <Helmet>
        <link rel="canonical" href={config.URL} />
        <meta property="og:url" content={config.URL} />
        <meta name="robots" content="index,nofollow" />
        <meta property="og:title" content={config.TITLE} />
        <meta
          property="og:description"
          name="description"
          content="Ich berate und begleite Sie und Ihre Organisation auf Basis der systemischen Transaktionsanalyse und Agilen Methoden"
        />
        <title>{config.TITLE}</title>
      </Helmet>
      <Slogan minHeight={100} backgroundColor={theme.palette.primary.dark} />
      <Portfolio
        minHeight={500}
        backgroundColor={theme.palette.secondary.light}
      />{" "}
      <AboutMe minHeight={500} backgroundColor={theme.palette.secondary.main} />
      <Vita minHeight={500} backgroundColor={theme.palette.secondary.light} />
      {developmentMode?.development ? (
        <Memberships
          minHeight={100}
          backgroundColor={theme.palette.secondary.main}
        />
      ) : undefined}
    </>
  );
}
