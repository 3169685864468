import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

import { GetInTouch } from "../../components/GetInTouch";
import { typoGraphySx } from "../../definitions/theme";

import type { PageProperties } from "../../types/PageProperties";
import type { Theme } from "@mui/material/styles";

export function AboutMe(props: PageProperties) {
  const { backgroundColor } = props;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        id="aboutme"
        container
        sx={{
          backgroundColor,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          size={{
            xs: 12,
            sm: 5,
          }}
          order={{ xs: 2, sm: 2 }}
          sx={{
            paddingTop: (curTheme: Theme) => curTheme.spacing(5),
            paddingBottom: (curTheme: Theme) => curTheme.spacing(5),
          }}
        >
          <Typography sx={typoGraphySx}>
            Zertifizierter transaktionsanalytischer Berater und
            systemisch-transaktionsanalytischer Coach
          </Typography>
          <Typography sx={typoGraphySx}>
            Coach, Berater und Trainer für den Aufbau von und der Führung in
            agilen Unternehmen und Organisationen
          </Typography>
          <Typography sx={typoGraphySx}>
            Berater und Coach für adaptives, agiles Change Management für
            Organisation und Kultur
          </Typography>
          <GetInTouch backgroundColor={backgroundColor} pt={5} pb={3} />
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 4,
          }}
          order={{ xs: 3, sm: 3 }}
        >
          {" "}
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 5,
          }}
          order={{ xs: 1, sm: 1 }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              padding: (curTheme: Theme) => curTheme.spacing(5),
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              sx={{
                width: { xs: "17rem", sm: "22rem" },
              }}
              alt="Dominik Elsbroek"
              src={"/dominik_elsbroek.webp"}
            />
            {/*           
          <Box
            component="img"
            sx={{
              width: { xs: "15rem", md: "20rem" },
            }}
            alt="Dominik Elsbroek"
            src={"/dominikelsbroek.webp"}
          /> 
          */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
