import Call from "@mui/icons-material/Call";
import Contacts from "@mui/icons-material/Contacts";
import EmailIcon from "@mui/icons-material/Email";
import Grid from "@mui/material/Grid2";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useContext } from "react";

import { DevelopmentContext } from "../context/useDevelopmentMode";
import config from "../definitions/config.json";
import { theme } from "../definitions/theme";

const contactHeight = 32;

const backgroundColor = theme.palette.primary.light;
const textColor = theme.palette.text.primary;

function inDevelopmentVersion() {
  return <></>;
}

function prodContact() {
  return (
    <Grid
      container
      sx={{
        backgroundColor: { backgroundColor },
        flexGrow: 1,
        alignContent: "center",
        minHeight: contactHeight,
      }}
    >
      <Grid
        order={{ xs: 1, sm: 1 }}
        size={{ xs: 12, sm: 4 }}
        sx={{
          paddingTop: { sm: 0, xs: 1 },
          paddingBottom: { sm: 0, xs: 1 },
          paddingRight: 5,
          paddingLeft: 5,
          alignContent: "center",
          textAlign: { xs: "center", sm: "start" },
        }}
      >
        <Typography component="div" sx={{ flexGrow: 1, fontSize: 15 }}>
          <Call fontSize="inherit" />
          &nbsp;
          <Link href="tel:+494261 8160667" underline="hover" color={textColor}>
            +49 (0) 4261 / 81 60 667
          </Link>
        </Typography>
      </Grid>

      <Grid
        order={{ xs: 3, sm: 2 }}
        size={{ xs: 12, sm: 4 }}
        sx={{
          paddingTop: { sm: 0, xs: 1 },
          paddingBottom: { sm: 0, xs: 1 },
          paddingRight: 5,
          paddingLeft: 5,
          alignContent: "center",
          textAlign: {
            xs: "center",
            sm: "center",
          },
        }}
      >
        <Typography component="div" sx={{ flexGrow: 1, fontSize: 15 }}>
          <Contacts fontSize="inherit" />
          &nbsp;
          <Link
            href="./Dominik Elsbroek.vcf"
            underline="hover"
            color={textColor}
          >
            Visitenkarte
          </Link>
        </Typography>
      </Grid>

      <Grid
        order={{ xs: 2, sm: 3 }}
        size={{ xs: 12, sm: 4 }}
        sx={{
          paddingTop: { sm: 0, xs: 1 },
          paddingBottom: { sm: 0, xs: 1 },
          paddingRight: 5,
          paddingLeft: 5,
          alignContent: "center",
          textAlign: {
            xs: "center",
            sm: "end",
          },
        }}
      >
        <Typography component="div" sx={{ flexGrow: 1, fontSize: 15 }}>
          <EmailIcon fontSize="inherit" />
          &nbsp;
          <Link
            href={`mailto:${config.EMAIL}`}
            underline="hover"
            color={textColor}
          >
            {config.EMAIL}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
}

export function Contact() {
  const developmentMode = useContext(DevelopmentContext);
  if (developmentMode?.development) {
    return inDevelopmentVersion();
  }
  return prodContact();
}
