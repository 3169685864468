import Grid from "@mui/material/Grid2";
import { Suspense, lazy, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { Route, Routes, useSearchParams } from "react-router-dom";

import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { Loader } from "./components/Loader";
import { NavBar } from "./components/NavBar";
import {
  DevelopmentContext,
  type DevelopmentContextType,
} from "./context/useDevelopmentMode";
import { fontUrl, theme } from "./definitions/theme";
import { Home } from "./pages/Home";

const Debug = lazy(() =>
  import("./pages/Debug").then((module) => ({ default: module.Debug })),
);
const Agb = lazy(() =>
  import("./pages/Agb").then((module) => ({ default: module.Agb })),
);
const Test = lazy(() =>
  import("./pages/Test").then((module) => ({ default: module.Test })),
);
const Cases = lazy(() =>
  import("./pages/cases/Cases").then((module) => ({ default: module.Cases })),
);
const Imprint = lazy(() =>
  import("./pages/Imprint").then((module) => ({ default: module.Imprint })),
);

function getDevRoutes() {
  return (
    <>
      <Route
        path="/debug"
        element={
          <Suspense fallback={<Loader />}>
            <Debug />
          </Suspense>
        }
      />
      <Route
        path="/test"
        element={
          <Suspense fallback={<Loader />}>
            <Test />
          </Suspense>
        }
      />
    </>
  );
}

export function appRoutes(developmentMode?: boolean) {
  const backgroundColor = theme.palette.primary.main;
  return (
    <>
      {" "}
      <Contact />
      <NavBar />
      <Grid container sx={{ flexGrow: 1 }}>
        <Routes>
          {developmentMode && getDevRoutes()}
          <Route
            path="/cases"
            element={
              <Suspense fallback={<Loader />}>
                <Cases />{" "}
              </Suspense>
            }
          />
          <Route
            path="/imprint"
            element={
              <Suspense fallback={<Loader />}>
                <Imprint />{" "}
              </Suspense>
            }
          />
          <Route
            path="/agb"
            element={
              <Suspense fallback={<Loader />}>
                <Agb />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <Suspense fallback={<Loader />}>
                <Home />
              </Suspense>
            }
          />
        </Routes>

      </Grid>
      <Grid
        size={12}
        sx={{
          backgroundColor: { backgroundColor },
          flexGrow: 1,
          alignContent: "center",
          textAlign: "center",
        }}>
        <Footer />
      </Grid>
    </>
  );
}

export function App() {
  const [searchParams] = useSearchParams();
  const dev = searchParams.get("dev");
  const [developmentMode] = useState<DevelopmentContextType>({
    development: dev != null && dev !== "false",
  });
  return (
    <HelmetProvider>
      <DevelopmentContext.Provider value={developmentMode}>
        <Helmet>
          <meta property="og:locale" content="de_DE" />
          <link href={fontUrl} rel="stylesheet" />
        </Helmet>
        <Suspense fallback={<Loader />}>
          {appRoutes(developmentMode.development)}
        </Suspense>
      </DevelopmentContext.Provider>
    </HelmetProvider>
  );
}
