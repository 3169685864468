import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";

import config from "../definitions/config.json";

import type { PageProperties } from "../types/PageProperties";

export function GetInTouch(props: PageProperties) {
  const { backgroundColor, pt, pb } = props;
  return (
    <Grid
      container
      sx={{
        align: "center",
        alignContent: "center",
        justifyContent: "center",
        background: backgroundColor,
        pb,
        pt,
      }}
    >
      <Button
        variant="contained"
        size="large"
        color="secondary"
        href={`mailto:${config.EMAIL}`}
      >
        Kontakt
      </Button>
    </Grid>
  );
}
